<template>
  <div v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
              <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label"><b class="title-header">アカウント登録</b></h3>
        </div>
      </div>
      <div class="card-body">
        <el-form
          ref="ruleForm"
          label-width="30%"
          class="demo-ruleForm demo-form"
          :model="ruleForm"
          @keyup.enter.native="confirm_modal"
        >

          <div class="row mb-8">
              <div class="col-12 col-md-10 col-xl-5">
                  <label class="el-form-item__label" for="">メールアドレス <span
                      class="text-danger">*</span></label>
                  <el-form-item class="item-title-form item-fome-base" label="" prop="mail">
                      <el-input maxlength="100" class="bg-gray-item"  placeholder="メールアドレスを入力"
                              v-model="ruleForm.mail"></el-input>
                  </el-form-item>
              </div>
          </div>
            <div class="row mb-8">
              <div class="col-12 col-md-10 col-xl-5">
                  <label class="el-form-item__label" for="">ステータス <span
                      class="text-danger">*</span></label>
                  <el-form-item class="item-title-form item-fome-base" label="" prop="user_status">
                      <el-select class="bg-gray-item"  placeholder="ステータス"
                          v-model="ruleForm.user_status" style="width:100%;" >
                          <el-option v-for="status in ArrStatus" :value="status.value" :label="status.label" :key="status.value">{{ status.label }}</el-option>
                      </el-select>
                  </el-form-item>
              </div>
          </div>
<!--          phase 2-->

            <div class="row mb-8">
                <div class="col-12 col-md-10 col-xl-5">
                    <label class="el-form-item__label" for=""> テナント </label>
                    <el-form-item class="item-title-form item-fome-base" label=""
                                  prop="tenant_code">
                        <el-select class="w-100 bg-gray-item" @change="changeTenant()" v-model="ruleForm.tenant_code" placeholder="選択してください">
                            <el-option label="なし" value=" "></el-option>
                            <el-option
                                v-for="(item, index) in listTenant"
                                :key="index"
                                :label="item.tenant_name"
                                :value="item.tenant_code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

          <div class="row mb-8">
              <div class="col-12 col-md-10 col-xl-5">
                  <label class="el-form-item__label" for=""> 権限名 <span
                      class="text-danger">*</span></label>
                  <el-form-item class="item-title-form item-fome-base" label=""
                                prop="role_id">
                      <el-select class="w-100 bg-gray-item" v-model="ruleForm.role_id" placeholder="選択してください">
                          <el-option
                              v-for="(item, index) in listApproval"
                              :key="index"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
              </div>
          </div>
            <div class="row mb-8">
                <div class="col-12 col-md-10 col-xl-5">
                    <label class="el-form-item__label" for="">電話番号 <span
                        class="text-danger">*</span></label>
                    <el-form-item class="item-title-form item-fome-base" label="" prop="phone">
                        <el-input maxlength="100" class="bg-gray-item"  placeholder="電話番号を入力"
                                  v-model="ruleForm.phone"></el-input>
                    </el-form-item>
                </div>
            </div>
            <!--          phase 2-->
          <el-form-item prop="action" class="button-search" label-width="0px">
            <el-button
              class="ml-20 pl-10 pr-10"
              type="primary"
              @click.prevent="confirm_modal"
              >入力完了</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  <Popup id="confirm" title="確認" :content="title" :title_button="close_popup">
    <b-button @click.prevent="register()" class="btn-primary">{{ $t("register_account") }}</b-button>
  </Popup>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>
<script>
import apiService from "@/core/services/api.service";
import * as constants from "@/core/config/constant";
import Popup from "@/view/components/Popup";
import {GET_LIST_ROLE_APPROVAL} from "@/core/services/store/approvalRole.module";
import {GET_ALL_TENANT} from "@/core/services/store/mtenant.module";
import {mapActions, mapGetters} from "vuex";
export default {
  components: {
    Popup
  },
  data() {
    return {
      ruleForm: {
        mail: "",
        user_status: 1,
        role_id:'',
        tenant_code:'',
        phone:'',
      },
      constants: constants,
      loading: false,
      title: "",
      ArrStatus: [],
      errorMessage: '',
      listApproval:[],
      listRole:[],
      listTenant:[],
      close_popup: this.$t("close_popup"),
      not_permisson_popup: false,
    };
  },
    computed:{
        ...mapGetters(['currentUser']),
    },
  created() {
      if (this.hasPermissonCheck('kengen_flg')){
          this.getListRoleApproval();
          this.getListTenant();
          this.ArrStatus = this.constants.USER_STATUS
      }else {
          this.not_permisson_popup = true
      }
  },
  methods: {
      ...mapActions([GET_ALL_TENANT]),
      getListRoleApproval() {
          this.$store.dispatch(GET_LIST_ROLE_APPROVAL).then(data => {
              if (data.code == constants.STATUS_CODE.SUCCESS) {
                  this.listRole = data.data;
                  this.listApproval = data.data.map(roleList => {
                      return {
                          label: roleList.role_name,
                          value: roleList.role_id
                      }
                  })
                  this.changeTenant();
              }
          }).catch(e => {
              this.notifyError(e)
          })
      },
     async getListTenant() {
          this.listTenant = await this.getAllTenant();
      },
    register() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.turnOffErrorMessage("ruleForm");
          apiService
            .post(`/user/register-account`, this.ruleForm)
            .then((data) => {
              this.loading = false;
              if (data && data.data && data.data.data) {

                if (data.data.code === constants.STATUS_CODE.SUCCESS) {
                  this.notifySuccess(this.$t("REGISTER_SUCCESS"));
                  let self = this;
                  setTimeout(function () {
                    self.$router.push({
                      name: "list-account",
                    });
                  }, 500);
                  return;
                }
                if (data.data.code === constants.STATUS_CODE.BAD_REQUEST) {
                  this.showErrorMessage(data.data.data, "ruleForm");
                  this.$bvModal.hide("confirm");
                } else {
                  this.notifyError(data.data.message);
                  this.$bvModal.hide("confirm");
                  this.loading = false;
                }
              } else {
                this.notifyError(this.$t("ERROR_SERVER"))
                this.$bvModal.hide("confirm");
                this.loading = false;
              }
            })
            .catch((e) => {
              this.notifyError(e);
              this.$bvModal.hide("confirm");
              this.loading = false;
            });
        } else {
          this.$bvModal.hide("confirm");
        }
      });
    },
    confirm_modal() {
      if (this.ruleForm.mail == "") {
        this.title = this.$t('RULE_FORM.message_title');
        this.$bvModal.show("confirm");
      } else{
        this.title = this.$t("Confirm_register",{mail: this.ruleForm.mail});
        this.$bvModal.show("confirm");
      }
    },
      changeTenant(){
          this.ruleForm.role_id = '';
          this.listApproval = this.listRole.filter(roleList => {
              if (this.ruleForm.tenant_code && roleList.tenant_code === this.ruleForm.tenant_code){
                  return true;
              }else if((this.ruleForm.tenant_code === ' ' || !this.ruleForm.tenant_code)
                  && !this.currentUser.super_user && this.currentUser.tenant_code === roleList.tenant_code){
                  return true;
              }else if((this.ruleForm.tenant_code === ' ' || !this.ruleForm.tenant_code) && this.currentUser.super_user && roleList.tenant_code === null){
                return true;
              }
              return false;
          }).map(roleList => {
              return {
                  label: roleList.role_name,
                  value: roleList.role_id
              }
          });
      },
  },
};
</script>

<style lang="scss" scoped>
.card-custom {
    border-radius: 15px;
}

.card-custom > .card-header {
    padding: 0;
    margin: 0px 21px;
}
.font-label-custom {
    font-weight: bold;
    font-size: 16px;
}
</style>
